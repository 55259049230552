// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adarsh-park-heights-js": () => import("./../../../src/pages/adarsh-park-heights.js" /* webpackChunkName: "component---src-pages-adarsh-park-heights-js" */),
  "component---src-pages-adarsh-savana-js": () => import("./../../../src/pages/adarsh-savana.js" /* webpackChunkName: "component---src-pages-adarsh-savana-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-siteandfloorplan-js": () => import("./../../../src/pages/siteandfloorplan.js" /* webpackChunkName: "component---src-pages-siteandfloorplan-js" */),
  "component---src-pages-vituralsitetour-js": () => import("./../../../src/pages/vituralsitetour.js" /* webpackChunkName: "component---src-pages-vituralsitetour-js" */)
}

